import Hammer from "../../bundles/hammer";

import {requestAnimationFrame} from 'CommonUtils/raf.js';




class Slider {
    constructor(breakpointsSettings, isRTL) {
        this.breakpointsSettings = breakpointsSettings;
        this.sliderSettings = this.setSliderSettings();
        this.item = this.sliderSettings.item;
        this.slideMove = this.sliderSettings.slideMove;
        this.slideMargin = this.sliderSettings.slideMargin;
        this.slideWidth;
        this.slidesNumber;
        this.isRTL = isRTL;

        requestAnimationFrame(() => {
            this.elSlider = document.querySelector(".slider");
            this.elSlidesContainer = document.querySelector(".slides-container");
            this.elSliderBtnsWrapper = document.querySelector(".slider-btns-wrapper");
            this.slidesNumber = document.querySelectorAll(".slide").length;


            this.resetSlider();

            if (isRTL) this.setRTL();

            const hammer = new Hammer(this.elSlider);
            hammer.on("swipeleft", () => this.move(isRTL ? "left" : "right"));
            hammer.on("swiperight", () => this.move(isRTL ? "right" : "left"));


            let previousTimestamp;
            let timeout;
            window.addEventListener('resize', e => {

                timeout = requestAnimationFrame(timestamp => {
                    if (previousTimestamp === undefined) {
                        previousTimestamp = timestamp;
                        this.onResize();
                    }
                    if (timestamp !== previousTimestamp) {
                        // Run our resize functions
                        previousTimestamp = timestamp;
                        this.onResize();
                    }
                });

            }, false);
            
            this.elSlider.addEventListener('mousedown', ()=> {
                if(this.elSlider.style.cursor = 'grab') this.elSlider.style.cursor = 'grabbing';
            });
            this.elSlider.addEventListener('mouseup', ()=> {
                if(this.elSlider.style.cursor = 'grabbing') this.elSlider.style.cursor = 'grab';
               
            });

            

        });
    }

    getButtonsNumber() {
        return Math.ceil((this.slidesNumber - this.item) / this.slideMove + 1);
    }

    createSliderButtons() {
        const sliderBtns = [];
        for (let i = 0; i < this.buttonsNumber; i++) {
            let slidesToMove;
            let px;
            const elSliderBtn = document.createElement("li");

            if (i < this.buttonsNumber - 1) {
                //Standard button
                slidesToMove = this.slideMove * i;
                px = -slidesToMove * (this.slideMargin + this.slideWidth);
            } else {
                //Handles the last button px number(In case slidesNumber is not divided by  sliderSettings.slideMove)
                slidesToMove = this.slidesNumber - this.item;
                px = -slidesToMove * (this.slideMargin + this.slideWidth);
            }
            elSliderBtn.addEventListener("click", () => this.onBtnClick(px, i, slidesToMove));
            sliderBtns.push(elSliderBtn);
        }
        sliderBtns[0].classList.add("active");
        this.elSliderBtnsWrapper.replaceChildren(...sliderBtns);
        this.elSliderBtns = this.elSliderBtnsWrapper.children;
        this.activeBtnNumber = 1;
        this.elSliderBtnsWrapper.style.visibility = this.slidesNumber === this.item ? 'hidden' : 'visible';
    }

    setSlidesWidth() {
        const elSlides = Array.from(this.elSlidesContainer.children);
        this.slideWidth = this.culcSlideWidth();
        elSlides.forEach((slide) => {
            slide.style.width = this.slideWidth + "px";
            slide.style.marginRight = this.slideMargin + "px";
        });
    }

    culcSlideWidth() {
        return (this.elSlider.offsetWidth - (this.slideMargin * (this.item - 1))) / this.item;
    }

    setRTL() {
        this.elSlider.classList.add("mirror-flip");
        const elSlides = Array.from(this.elSlidesContainer.children);
        elSlides.forEach((slide) => slide.classList.add("mirror-flip"));
        this.elSliderBtnsWrapper.classList.add('mirror-flip');
    }

    calcRight() {
        //determines if we are in the last swipe available to the right and  changes the value slidesToMove accordingly
        let slidesToMove =
            this.revealedNumber + this.slideMove <= this.slidesNumber
                ? this.slideMove
                : this.slidesNumber - this.revealedNumber;
        this.revealedNumber += slidesToMove;
        let pxToMoveRight = -slidesToMove * (this.slideMargin + this.slideWidth);
        return pxToMoveRight;
    }

    calcLeft() {
        //determines if we are in the first swipe to the left and changes the value slidesToMove accordingly
        let slidesToMove =
            this.revealedNumber !== this.slidesNumber
                ? this.slideMove
                : this.slidesNumber - (this.item + this.slideMove * (this.activeBtnNumber - 2));
        this.revealedNumber -= slidesToMove;
        let pxToMoveLeft = slidesToMove * (this.slideMargin + this.slideWidth);
        return pxToMoveLeft;
    }

    slideRight() {
        if (this.revealedNumber === this.slidesNumber) return;
        this.pxToMove += this.calcRight();
        this.activeBtnNumber++;
        this.elSliderBtns[this.activeBtnNumber - 1].classList.toggle("active");
        this.elSliderBtns[this.activeBtnNumber - 2].classList.toggle("active");
    }

    slideLeft() {
        if (this.revealedNumber === this.item) return;
        this.pxToMove += this.calcLeft();
        this.activeBtnNumber--;
        this.elSliderBtns[this.activeBtnNumber].classList.toggle("active");
        this.elSliderBtns[this.activeBtnNumber - 1].classList.toggle("active");
    }

    move(direction) {
        requestAnimationFrame(() => {
            direction === "right" ? this.slideRight() : this.slideLeft();
            this.elSlidesContainer.style.setProperty("transform", `translateX(${this.pxToMove}px)`);
        });
    }

    onBtnClick(px, idx, slidesMoved) {
        this.revealedNumber = this.item + slidesMoved;
        this.pxToMove = px;
        requestAnimationFrame(() => {
            this.elSliderBtns[this.activeBtnNumber - 1].classList.remove("active");
            this.activeBtnNumber = idx + 1;
            this.elSliderBtns[idx].classList.add("active");
            this.elSlidesContainer.style.setProperty("transform", `translateX(${this.pxToMove}px)`);
        });
    }

    setSliderSettings() {
        let settings;
        let screenWidth = window.innerWidth;
        if (screenWidth > this.breakpointsSettings[0].breakpoint) return this.breakpointsSettings[0];
        for (let i = 0; i < this.breakpointsSettings.length; i++) {
            if (screenWidth <= this.breakpointsSettings[i].breakpoint) settings = this.breakpointsSettings[i];
        }
        return settings;
    }

    resetSlider() {
        this.pxToMove = 0;
        this.activeBtnNumber = 1;
        this.revealedNumber = this.item;
        this.buttonsNumber = this.getButtonsNumber();
        requestAnimationFrame(() => {
            this.elSlidesContainer.style.setProperty("transform", "translateX(0px)");
            this.setSlidesWidth();
            this.createSliderButtons();
        })
    }

    onResize() {
        this.sliderSettings = this.setSliderSettings();
        this.item = this.sliderSettings.item;
        this.slideMove = this.sliderSettings.slideMove;
        this.slideMargin = this.sliderSettings.slideMargin;
        this.resetSlider();
    }
}

export default Slider;

